<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/digitalService' }"
          >扩容提质服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>聚合码详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <div class="img_box">
          <img src="../../assets/img/detail/detail1.png" alt="" />
        </div>
        <p>
          “不同时代要选不同的管理工具，
          传统财务软件仅仅是财务核算部分的一个工作工具，
          而金卡易联代账除了精准、智能的财务核算部分，
          最突出的亮点是代账运营管理平台。通过数据看板，
          我可以实时看到每位员工的工作进度；通过智能数据检测，
          系统对有问题的节点给出预警提示， 不仅帮我们实现了对员工的科学化管理，
          更实现了对财税风险的全面监控； “不同时代要选不同的管理工具，
          传统财务软件仅仅是财务核算部分的一个工作工具，
          而金卡易联代账除了精准、智能的财务核算部分，
          最突出的亮点是代账运营管理平台。
          通过数据看板，我可以实时看到每位员工的工作进度；
          通过智能数据检测，系统对有问题的节点给出预警提示，
          不仅帮我们实现了对员工的科学化管理， 更实现了对财税风险的全面监控；
          “不同时代要选不同的管理工具，
          传统财务软件仅仅是财务核算部分的一个工作工具，
          而金卡易联代账除了精准、智能的财务核算部分，
          最突出的亮点是代账运营管理平台。
          通过数据看板，我可以实时看到每位员工的工作进度；
          通过智能数据检测，系统对有问题的节点给出预警提示，
          不仅帮我们实现了对员工的科学化管理， 更实现了对财税风险的全面监控；
          “不同时代要选不同的管理工具，
          传统财务软件仅仅是财务核算部分的一个工作工具，
          而金卡易联代账除了精准、智能的财务核算部分，
          最突出的亮点是代账运营管理平台。
          通过数据看板，我可以实时看到每位员工的工作进度；
          通过智能数据检测，系统对有问题的节点给出预警提示，
          不仅帮我们实现了对员工的科学化管理， 更实现了对财税风险的全面监控；
        </p>
      </div>
    </div>

    <div class="btn" @click="subInfo">立即体验</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    }
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
}

.img_box img {
  display: block;
  width: 100%;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 40px 0;
  text-align: justify;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}
</style>
